.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    height: 80vh;
    width: 100vw;
  }
}

.row {
  position: relative;
}

.container {
  position: absolute;
  width: 60vw;
  height: 90vh;
  overflow: hidden;
  background: white;
  border-radius: 30px;
  top: -30vh;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 60vw;
    height: 50vh;
    top: -25vh;
  }
}

.container .image {
  height: 100%;
  width: 100%;
}

:root {
  --level-one: translateZ(1rem);
  --level-two: translateZ(3rem);
  --level-three: translateZ(5rem);

  --fw-normal: 400;
  --fw-bold: 700;

  --clr: #b7c9e5;
}

.card {
  position: absolute;
  width: 50vw;
  overflow: hidden;
  border-radius: 30px;
  top: -50vh;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 60vw;
    height: 50vh;
    top: -25vh;
  }
}

.card__content {
  text-align: center;
  position: relative;
  padding: 15em 5em;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.card:hover .card__hover {
  transform: rotateY(0.5turn);
}

.card__front,
.card__back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5em 3em;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  display: grid;
  align-content: center;
  border-radius: 25px;
}

.card__front {
  background-size: cover;
  background-blend-mode: overlay;
  color: #333;
}

.card__front::before {
  content: "";
  position: absolute;
  --spacer: 1em;
  top: var(--spacer);
  bottom: var(--spacer);
  left: var(--spacer);
  right: var(--spacer);
  border: 1px solid #244a30;
  border-radius: 30px;
  transform: var(--level-one);
}

.card__title {
  transform: var(--level-three);
  order: 2;
}

.card__subtitle {
  transform: var(--level-two);
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.75rem;
  font-weight: var(--fw-bold);
  opacity: 0.7;
}

.card__body {
  transform: var(--level-two);
  font-weight: var(fw-normal);
  font-size: 1.5rem;
  line-height: 1.6;
}

.card__back {
  transform: rotateY(0.5turn);
  color: var(--clr);
  background: #231f20;
}
