.hover-button {
  position: relative;
  color: #7e936c;
  font-size: 16px;
  text-decoration: none;
  padding: 15px 20px;
  transition: 0.2s;
  font-family: PoppinsBold;
  background: #474747;
  border-radius: 5px;
  width: 140px;
  height: 50px;
  margin-left: 20px;
  z-index: 1;
  overflow: hidden;
}

.hover-button:hover {
  scale: 1.1;
}

.hover-button i {
  position: absolute;
  inset: -2px;
  display: block;
  border-radius: 5px;
}

.hover-button i,
.hover-button i:nth-child(2) {
  background: linear-gradient(var(--x), #00ccff, #ff7272, #41be69);
}

.hover-button i:nth-child(2) {
  filter: blur(10px);
}
.hover-button span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #231f20;
  color: white;
  z-index: 1;
  letter-spacing: 1px;
  border: 1px solid #231f20;
  border-radius: 3px;
}

.hover-button span::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.075);
  transform: skew(25deg);
  z-index: 2;
}

.ripple-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ripple-container .ripple {
  position: absolute;
  background: #fff;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  animation: animate 1s linear infinite;
  z-index: 5;
}
@keyframes animate {
  0% {
    width: 0;
    height: 0;
    opacity: 0.2;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}
