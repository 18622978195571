@font-face {
  font-family: "ClashDisplay";
  src: local("ClashDisplay"),
    url(./fonts/ClashDisplay-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Thin.otf) format("opentype");
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"), url(./fonts/Poppins-Light.otf) format("opentype");
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"), url(./fonts/Poppins-Regular.otf) format("opentype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  background-color: #231f20;
  @apply text-white;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.promotion {
  display: flex;
  width: 90%;
  height: 90%;
  background-color: #317752;
  border-radius: 0.75 rem;
  padding: 2 rem;
  opacity: 0.8;
  margin: auto;
}

.margin-transition {
  transition: margin-left 1s;
  transition-delay: 250ms;
}

.margin-opacity {
  transition: opacity 1s;
}

.initial-child-container {
  height: 100%;
  /* gap: 10px; */
}

.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-docker::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.contact-video {
  background: linear-gradient(180deg, rgba(249, 249, 246, 0) 0%, #f9f9f6 100%),
    linear-gradient(
      0deg,
      rgba(249, 249, 246, 0.5) 0%,
      rgba(249, 249, 246, 0.5) 100%
    );
}

.dentio-page-transition {
  padding: 20px;
  position: fixed;
  inset: 0;
  margin: auto;
  width: max-content;
  height: max-content;
}
