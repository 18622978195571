.office-app {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    height: 80vh;
    width: 100vw;
  }
}

.office-row {
  position: relative;
}

.office-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: white;
  top: -50vh;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 60vw;
    height: 50vh;
    top: -25vh;
  }
}

.office-container .office-image {
  height: 100vh;
  width: 100vw;
}
