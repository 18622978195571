.square-button {
  position: relative;
  color: #7e936c;
  font-size: 16px;
  text-decoration: none;
  padding: 15px 20px;
  transition: 0.2s;
  font-family: PoppinsBold;
  background: #ececec;
}

.square-button:hover {
  color: #244a30;
  text-shadow: 0 0 1px #244a30;
  letter-spacing: 1px;
}

.square-button span {
  position: absolute;
  display: block;
  background: #244a30;
  border-radius: 9999px;
}

.square-button span:nth-child(1) {
  left: 0;
  top: 0;
  width: 50.5%;
  height: 1.5px;
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 0.1s;
}

.square-button:hover span:nth-child(1) {
  transform: scaleX(1);
  transform-origin: right;
}

.square-button span:nth-child(2) {
  right: 0;
  top: 0;
  width: 50.5%;
  height: 1.5px;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.1s;
}

.square-button:hover span:nth-child(2) {
  transform: scaleX(1);
  transform-origin: left;
}

.square-button span:nth-child(3) {
  right: 0;
  top: 0;
  width: 1.5px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.1s;
  transition-delay: 0.1s;
}

.square-button:hover span:nth-child(3) {
  transform: scaleY(1);
  transform-origin: top;
}

.square-button span:nth-child(4) {
  left: 0;
  top: 0;
  width: 1.5px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.1s;
  transition-delay: 0.1s;
}

.square-button:hover span:nth-child(4) {
  transform: scaleY(1);
  transform-origin: top;
}

.square-button span:nth-child(5) {
  left: 0;
  bottom: 0;
  width: 50.5%;
  height: 1.5px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.1s;
  transition-delay: 0.2s;
}

.square-button:hover span:nth-child(5) {
  transform: scaleX(1);
  transform-origin: left;
}

.square-button span:nth-child(6) {
  right: 0;
  bottom: 0;
  width: 50.5%;
  height: 1.5px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.1s;
  transition-delay: 0.2s;
}

.square-button:hover span:nth-child(6) {
  transform: scaleX(1);
  transform-origin: right;
}
